import { Button, Input } from "@components/library";
import { useTranslation } from "@helpers/translate";

import { createBonnus } from "@actions/bonnus/bonnusCreate";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { bonnusAdapter } from "./adapter/bonnus.adapter";
import "./index.sass";

export const Bonnus = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { __ } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm();
  const user = useSelector((state) => state.auth.user);

  const onSubmit = (formData) => {
    setLoading(true);
    const data = bonnusAdapter(formData, user.id);

    dispatch(createBonnus({ data }))
      .then(() => {
        reset();
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>
          {__("bonnus.title")} {__("seo.divider")} {__("seo.title")}
        </title>
      </Helmet>
      <div>
        <p className="bonnus__title">{__("bonnus.title_page")}</p>
        <form
          className="bonnus__form_container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            data={{
              label: `${__("bonnus.label_randomcode")}:`,
              type: "text",
              placeholder: "XLDI9A78AA64",
              errors,
              settings: {
                ...register("random-code", {
                  required: true,
                }),
              },
              message: `${__("error.email_format")}`,
              name: "code",
            }}
          />
          <div className="bonnus__button-form-container">
            <Button
              className="bonnus__button-submit"
              isLoading={loading}
              disabled={!isValid}
              type="submit"
            >
              {__("bonnus.button_submit")}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
