import React from "react";

export const WalletIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2667 13.9591C17.9917 16.1758 16.175 17.9925 13.9584 18.2675C12.6167 18.4341 11.3667 18.0675 10.3917 17.3508C9.83338 16.9425 9.96671 16.0758 10.6334 15.8758C13.1417 15.1175 15.1167 13.1341 15.8834 10.6258C16.0834 9.96748 16.95 9.83415 17.3584 10.3841C18.0667 11.3675 18.4334 12.6175 18.2667 13.9591Z"
        fill="#707070"
      />
      <path
        d="M8.32508 1.66602C4.65008 1.66602 1.66675 4.64935 1.66675 8.32435C1.66675 11.9993 4.65008 14.9827 8.32508 14.9827C12.0001 14.9827 14.9834 11.9993 14.9834 8.32435C14.9751 4.64935 12.0001 1.66602 8.32508 1.66602ZM7.54175 7.39102L9.55008 8.09102C10.2751 8.34935 10.6251 8.85768 10.6251 9.64102C10.6251 10.541 9.90841 11.2827 9.03341 11.2827H8.95841V11.3243C8.95841 11.666 8.67508 11.9493 8.33341 11.9493C7.99175 11.9493 7.70841 11.666 7.70841 11.3243V11.2743C6.78341 11.2327 6.04175 10.4577 6.04175 9.49102C6.04175 9.14935 6.32508 8.86602 6.66675 8.86602C7.00841 8.86602 7.29175 9.14935 7.29175 9.49102C7.29175 9.79102 7.50841 10.0327 7.77508 10.0327H9.02508C9.21675 10.0327 9.36675 9.85768 9.36675 9.64102C9.36675 9.34935 9.31675 9.33268 9.12508 9.26602L7.11675 8.56602C6.40008 8.31602 6.04175 7.80768 6.04175 7.01602C6.04175 6.11602 6.75841 5.37435 7.63341 5.37435H7.70841V5.34102C7.70841 4.99935 7.99175 4.71602 8.33341 4.71602C8.67508 4.71602 8.95841 4.99935 8.95841 5.34102V5.39102C9.88341 5.43268 10.6251 6.20768 10.6251 7.17435C10.6251 7.51602 10.3417 7.79935 10.0001 7.79935C9.65841 7.79935 9.37508 7.51602 9.37508 7.17435C9.37508 6.87435 9.15841 6.63268 8.89175 6.63268H7.64175C7.45008 6.63268 7.30008 6.80768 7.30008 7.02435C7.29175 7.30768 7.34175 7.32435 7.54175 7.39102Z"
        fill="#707070"
      />
    </svg>
  );
};
