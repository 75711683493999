import { LOCALE } from "../../constants";
import { intermediaryInstance } from "@helpers/axios/private.axios";

export const changeLocale =
  ({ locale, user_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOCALE.LOADING_LOCALE });
      await intermediaryInstance.patch(
        "/user/update-user-with-values",
        {
          values: [
            {
              name: "locale",
              value: locale,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            user_id,
          },
        }
      );
      dispatch({ type: LOCALE.CHANGE_LOCALE, payload: locale });
    } catch (e) {
      dispatch({ type: LOCALE.END_LOADING_LOCALE });
    }
  };
