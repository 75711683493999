const storageKeys = {
  studyTemplates: "SaveLoadAdapter_studyTemplates",
};

export default class SaveLoadAdapter {
  _studyTemplates = [];
  _isDirty = false;

  constructor() {
    this._loadFromLocalStorage();
    setInterval(() => {
      if (this._isDirty) {
        this._saveAllToLocalStorage();
        this._isDirty = false;
      }
    }, 1000);
  }

  _loadFromLocalStorage() {
    try {
      const storedTemplates = this._getFromLocalStorage(
        storageKeys.studyTemplates
      );
      this._studyTemplates = storedTemplates ? storedTemplates : [];
    } catch (error) {
      this._studyTemplates = [];
    }
  }

  removeStudyTemplate(studyTemplateData) {
    const index = this._studyTemplates.findIndex(
      (template) => template.name === studyTemplateData.name
    );
    if (index === -1) {
      return Promise.reject(new Error("The study template does not exist"));
    }
    this._studyTemplates.splice(index, 1);
    this._isDirty = true;
    return Promise.resolve();
  }

  getStudyTemplateContent(studyTemplateData) {
    const template = this._studyTemplates.find(
      (template) => template.name === studyTemplateData.name
    );
    if (!template) {
      return Promise.reject(new Error("The study template does not exist"));
    }
    return Promise.resolve(template.content);
  }

  saveStudyTemplate(studyTemplateData) {
    this._studyTemplates = this._studyTemplates.filter(
      (template) => template.name !== studyTemplateData.name
    );
    this._studyTemplates.push(studyTemplateData);
    this._isDirty = true;
    return Promise.resolve();
  }

  getAllStudyTemplates() {
    return Promise.resolve(this._studyTemplates);
  }

  _getFromLocalStorage(key) {
    const dataFromStorage = window.localStorage.getItem(key);
    return dataFromStorage ? JSON.parse(dataFromStorage) : null;
  }

  _saveToLocalStorage(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  _saveAllToLocalStorage() {
    this._saveToLocalStorage(storageKeys.studyTemplates, this._studyTemplates);
  }
}
