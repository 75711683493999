import axios from "@helpers/axios/public.axios";
import { AUTH } from "../../constants";

export const invalidateToken =
  ({ token }) =>
  async (dispatch) => {
    try {
      await axios.post(
        "/auth/invalidate-token",
        {},
        {
          headers: {
            access_token: token,
          },
        }
      );
    } catch (e) {
      dispatch({ type: AUTH.AUTH_ERROR, payload: e.response.data.message });
      throw e;
    }
  };
