import { BIDS } from "../../constants";
import { intermediaryInstance } from "@helpers/axios/private.axios";
import store from "../../redux/store";

export const getClosedHistory =
  ({ count = 1 }) =>
    async (dispatch) => {
      try {
        const state = store.getState();

        const walletType = state.wallet.active_wallet.type;

        dispatch({ type: BIDS.LOADING_CLOSED_HISTORY });
        setTimeout(async () => {
          const resp = await intermediaryInstance.get(
            `/bid/closed/${walletType ? walletType : "demo"}?start=${count}`
          );
          const data = resp.data;
          dispatch({
            type: BIDS.CHANGE_CLOSED_HISTORY,
            payload: {
              data: data.data.items,
              total: data.data.pagination.totalItems,
            },
          });
        }, 1000)
      } catch (e) {
        throw new Error(e);
      }
    };
