import React from "react";

export const RightArrowIcon = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45337 4.5535L5.45337 8.70684V12.4468C5.45337 13.0868 6.2267 13.4068 6.68004 12.9535L10.1334 9.50017C10.6867 8.94683 10.6867 8.04683 10.1334 7.4935L8.82004 6.18017L6.68004 4.04017C6.2267 3.5935 5.45337 3.9135 5.45337 4.5535Z"
        fill="#707070"
      />
    </svg>
  );
};

export default RightArrowIcon;
