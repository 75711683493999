import { Grid } from "@mui/material";
import { useTranslation } from "@helpers/translate";

export const CollapseOpen = ({
  openTime,
  closeTime,
  type,
  payout,
  status,
  profit,
  opening_price,
}) => {
  const { __ } = useTranslation();

  return (
    <>
      <Grid container columnSpacing="1rem" rowSpacing="20px">
        <Grid item xs={6}>
          <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
            {__("trading.open_time")}:
          </h5>
          <time className="historyDayItem__text">
            <strong>{openTime}</strong>
          </time>
        </Grid>
        <Grid item xs={6}>
          <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
            {__("trading.closing_at")}:
          </h5>
          <time className="historyDayItem__text">
            <strong>{closeTime}</strong>
          </time>
        </Grid>
        <Grid item xs={6}>
          <ul className="historyDayItem__list">
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.forecast")}:
              </h5>
              <time className="historyDayItem__text" style={{ color: "#FFFF" }}>
                <strong>
                  {type === "up" ? __("trading.up") : __("trading.down")}
                </strong>
              </time>
            </li>
            <br />
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.payout")}:
              </h5>
              <time className="historyDayItem__text">
                <strong>{payout}%</strong>
              </time>
            </li>
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.profit")}:
              </h5>
              <time
                className={`historyDayItem__text ${
                  status === 2
                    ? "historyDayItem__text--green"
                    : status === 0
                    ? "historyDayItem__text--red"
                    : ""
                }`}
              >
                <strong>${profit}</strong>
              </time>
            </li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <ul className="historyDayItem__list">
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.open_price")}:
              </h5>
              <time className="historyDayItem__text">
                <strong>{opening_price}</strong>
              </time>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};
