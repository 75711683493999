import React from "react";
import Unverified from "@assets/images/icons/check.svg";
import Verified from "@assets/images/icons/success.svg";
import Pending from "@assets/images/icons/pending.svg";

import "./index.sass";
import { useTranslation } from "@helpers/translate";
import { useDispatch } from "react-redux";
import { WALLET } from "src/constants";
import { transactionTransform } from "./helpers/transactionTransform";

export const Status = ({ status, transaction }) => {
  const { __ } = useTranslation();
  const dispatch = useDispatch();

  const transactinConverted = transactionTransform(transaction);

  const onResumePayment = () => {
    dispatch({
      type: WALLET.SHOW_WITHDRAWAL,
      payload: transactinConverted,
    });
  };

  const statusMap = {
    success: "verified",
    pending: "pending",
    unverified: "unverified",
    2: "verified",
    1: "pending",
    0: "unverified",
    10: "unverified",
  };

  const textMap = {
    success: __("common.verified"),
    pending: __("common.pending"),
    unverified: __("common.unverified"),
    pending_verify: __("common.pending_verification"),
    2: __("common.verified"),
    1: __("common.pending"),
    0: __("common.unverified"),
    10: __("common.pending_verification"),
  };

  const iconMap = {
    success: Verified,
    pending: Pending,
    unverified: Unverified,
    0: Unverified,
    2: Verified,
    1: Pending,
    10: Pending,
  };

  return (
    <>
      {status == 10 ? (
        <button
          className={`status ${statusMap[status] || statusMap.default}`}
          onClick={onResumePayment}
        >
          <img
            className="status__icon"
            src={`${iconMap[status] || statusMap.default}`}
          />
          <p className="status__text">{textMap[status]}</p>
        </button>
      ) : (
        <div className={`status ${statusMap[status] || statusMap.default}`}>
          <img
            className="status__icon"
            src={`${iconMap[status] || statusMap.default}`}
          />
          <p className="status__text">{textMap[status]}</p>
        </div>
      )}
    </>
  );
};

export default Status;
