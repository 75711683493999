import { USER } from "../../constants";
import { intermediaryInstance } from "@helpers/axios/private.axios";

export const getUserData = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: USER.USER_REQUEST });
    const response = await intermediaryInstance.get(
      "/user/find-customer/info/me",
      {
        headers: {
          user_id,
        },
      }
    );

    const {
      item,
      item: { is_banned },
    } = response.data.data;

    if (!is_banned) {
      dispatch({
        type: USER.USER_RESPONSE,
        payload: { user: item },
      });
    }

    return { is_banned };
  } catch (error) {
    console.error(error);
  }
};
