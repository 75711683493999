import { intermediaryInstance } from "@helpers/axios/private.axios";
import { ALERT } from "../../constants";

export const createBonnus = ({ data }) => {
  return async (dispatch) => {
    try {
      const response = await intermediaryInstance.post(
        "/promotional-code/redeem",
        data
      );
      dispatch({
        type: ALERT.ALERT_SUCCESS,
        payload: "Completed",
      });

      return response.data;
    } catch (error) {
      const { kindMessage } = error.response.data;
      dispatch({ type: ALERT.ALERT_ERROR, payload: kindMessage });
      throw error;
    }
  };
};
