import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Mode from "@components/layout/Header/components/Mode";
import {
  HistoryIcon,
  ProfileIcon,
  SettingsIcon,
  WalletIcon,
  TradingIcon,
  LogoutIcon,
  BonnusIcon,
} from "@components/library";
import { useDispatch, useSelector } from "react-redux";
import { removeUserSession } from "@helpers/axios/private.axios";
import { useTranslation } from "@helpers/translate";
import { AUTH_PAGE, MAIN_PAGE, AUTH } from "@constants";

const MobileSidebar = ({ open, setOpen }) => {
  const { full_name = "", email } = useSelector((state) => state.user.user);
  const { isDemoUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { __ } = useTranslation();
  const logout = () => {
    navigate("/");
    removeUserSession();
  };

  const openAuthPopup = () => {
    dispatch({ type: AUTH.AUTH_CHANGE_POPUP, payload: true });
  };

  return (
    <aside
      onClick={() => setOpen(false)}
      className={`mobile-sidebar ${open ? "active" : ""}`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="mobile-sidebar__wrapper"
      >
        <header className="mobile-sidebar__header">
          {full_name && <h3 className="mobile-sidebar__name">{full_name}</h3>}
          {email && <p className="mobile-sidebar__email">{email}</p>}
          <ul className="mobile-sidebar__info-list">
            <li className="mobile-sidebar__info-item">
              <Mode />
            </li>
          </ul>
        </header>

        <nav>
          <ul className="mobile-sidebar__list">
            <li className="mobile-sidebar__item">
              <NavLink
                onClick={() => setOpen(false)}
                to={isDemoUser ? AUTH_PAGE : MAIN_PAGE}
                className="mobile-sidebar__nav-link"
              >
                <TradingIcon className="mobile-sidebar__nav-icon" />

                <p className="mobile-sidebar__nav-text">
                  {__("common.trading")}
                </p>
              </NavLink>
            </li>
            {isDemoUser ? (
              <>
                <li className="mobile-sidebar__item">
                  <button
                    type="button"
                    onClick={openAuthPopup}
                    className="mobile-sidebar__nav-link"
                  >
                    <WalletIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.wallet")}
                    </p>
                  </button>
                </li>
                <li className="mobile-sidebar__item">
                  <button
                    type="button"
                    onClick={openAuthPopup}
                    className="mobile-sidebar__nav-link"
                  >
                    <ProfileIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.profile")}
                    </p>
                  </button>
                </li>
                <li className="mobile-sidebar__item">
                  <button
                    type="button"
                    onClick={openAuthPopup}
                    className="mobile-sidebar__nav-link"
                  >
                    <HistoryIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.history")}
                    </p>
                  </button>
                </li>
                <li className="mobile-sidebar__item">
                  <button
                    type="button"
                    onClick={openAuthPopup}
                    className="mobile-sidebar__nav-link"
                  >
                    <SettingsIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.settings")}
                    </p>
                  </button>
                </li>
                <li className="mobile-sidebar__item">
                  <button
                    type="button"
                    onClick={openAuthPopup}
                    className="mobile-sidebar__nav-link"
                  >
                    <BonnusIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.bonnus")}
                    </p>
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="mobile-sidebar__item">
                  <NavLink
                    onClick={() => setOpen(false)}
                    to="/wallet"
                    className="mobile-sidebar__nav-link"
                  >
                    <WalletIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.wallet")}
                    </p>
                  </NavLink>
                </li>
                <li className="mobile-sidebar__item">
                  <NavLink
                    onClick={() => setOpen(false)}
                    to="/profile"
                    className="mobile-sidebar__nav-link"
                  >
                    <ProfileIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.profile")}
                    </p>
                  </NavLink>
                </li>
                <li className="mobile-sidebar__item">
                  <NavLink
                    onClick={() => setOpen(false)}
                    to="/trading-history"
                    className="mobile-sidebar__nav-link"
                  >
                    <HistoryIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.history")}
                    </p>
                  </NavLink>
                </li>
                <li className="mobile-sidebar__item">
                  <NavLink
                    onClick={() => setOpen(false)}
                    to="/settings"
                    className="mobile-sidebar__nav-link"
                  >
                    <SettingsIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.settings")}
                    </p>
                  </NavLink>
                </li>
                <li className="mobile-sidebar__item">
                  <NavLink
                    onClick={() => setOpen(false)}
                    to="/bonnus"
                    className="mobile-sidebar__nav-link"
                  >
                    <BonnusIcon className="mobile-sidebar__nav-icon" />

                    <p className="mobile-sidebar__nav-text">
                      {__("common.bonnus")}
                    </p>
                  </NavLink>
                </li>
              </>
            )}

            {!isDemoUser && (
              <li className="mobile-sidebar__item">
                <button
                  onClick={logout}
                  type="button"
                  className="mobile-sidebar__nav-link"
                >
                  <LogoutIcon className="mobile-sidebar__nav-icon" />

                  <p className="mobile-sidebar__nav-text">
                    {__("common.logout")}
                  </p>
                </button>
              </li>
            )}
          </ul>
        </nav>

        <button
          onClick={() => setOpen(false)}
          className="mobile-sidebar__close"
          type="button"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </aside>
  );
};

export default MobileSidebar;
