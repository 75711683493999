export const Boundary = {
  en: {
    "boundary.firstMessage":
      "An error occurred while loading the data, try refreshing the page.",
    "boundary.secondtMessage":
      "Report the problem to support through our chat and try logging in again.",
    "boundary.secondtButton": "Log out",
    "boundary.firstButton": "Refresh page",
    "boundary.paragraph":
      "If the problem persists, please contact our support team for further assistance.",
  },
  es: {
    "boundary.firstMessage":
      "Ocurrió un error inesperado al cargar los datos, intenta recargar la página.",
    "boundary.secondtMessage":
      "Reporte el problema a soporte a través de nuestro chat e intente iniciar sesión nuevamente.",
    "boundary.secondtButton": "Cerrar sesión",
    "boundary.firstButton": "Actualizar página",
    "boundary.paragraph":
      "Si el problema persiste, por favor contacte a nuestro equipo de soporte para obtener más ayuda.",
  },
};

export default Boundary;
