import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const RefreshIcon = ({ className }) => {
  const styles = useStyles();

  return (
    <>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3333 9.99984C18.3333 14.5998 14.6 18.3332 9.99996 18.3332C5.39996 18.3332 2.59163 13.6998 2.59163 13.6998M2.59163 13.6998H6.35829M2.59163 13.6998V17.8665M1.66663 9.99984C1.66663 5.39984 5.36663 1.6665 9.99996 1.6665C15.5583 1.6665 18.3333 6.29984 18.3333 6.29984M18.3333 6.29984V2.13317M18.3333 6.29984H14.6333"
          stroke={styles?.main}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
