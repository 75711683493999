import { useCallback, useEffect, useState } from "react";
import { Box, Pagination, Skeleton } from "@mui/material";
import Status from "@components/library/UI/Status";
import ConfirmWithdrawal from "@components/pages/Wallet/components/ConfirmWithdrawal";
import { intermediaryInstance } from "@helpers/axios/private.axios";
import { useTranslation } from "@helpers/translate";

const tableStyles = {
  head: { flex: "0 0 13%" },
  type: { flex: "0 0 8%" },
  amount: { flex: "0 0 11%" },
  network: { flex: "0 0 10%" },
  credited: { flex: "0 0 12%" },
  destination: { flex: "0 0 24%" },
  status: { flex: "0 0 12%" },
};

const Crypto = ({ operation }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const { __ } = useTranslation();
  const getFiatHistory = useCallback(async () => {
    try {
      setLoading(true);
      const operationParam =
        operation !== "all" ? `operation=${operation}` : "";
      const resp = await intermediaryInstance.get(
        `/gateway/history/crypto/transactions?${operationParam}&page=${currentPage}&paginate=10`
      );
      const { items, pagination } = resp.data.data;
      setData(items);
      setTotalPages(pagination.totalPages);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [currentPage, operation]);

  useEffect(() => {
    getFiatHistory();
  }, [getFiatHistory]);

  const changePage = (_, page) => setCurrentPage(page);

  const getTableCell = (content, style, className = "") => (
    <Box sx={style} className="table__td">
      <p className={`table__text ${className}`}>{content}</p>
    </Box>
  );

  const renderLoadingSkeleton = () =>
    [1, 2, 3].map((item) => (
      <div key={item} className="table__tr">
        <Skeleton className="table__skeleton" variant="rounded" width="100%" />
      </div>
    ));

  const renderTableRows = () =>
    data.map((el) => (
      <div key={el.id} className="table__tr">
        {getTableCell(el.created_at, tableStyles.head)}
        {getTableCell(
          el.operation === "deposit"
            ? __("wallet.deposit_type")
            : __("wallet.withdraw_type"),
          tableStyles.type,
          "table__text--bg"
        )}
        {getTableCell(el.amount, tableStyles.amount)}
        {getTableCell("TRX", tableStyles.network)}
        {getTableCell(
          `${el.amount_credited} ${el.currency}`,
          tableStyles.credited
        )}
        <Box sx={tableStyles.destination} className="table__td">
          <a href="#" className="table__text table__text--blue">
            {el.payee_wallet}
          </a>
        </Box>
        <Box sx={tableStyles.status} className="table__td">
          <Status status={el.status} transaction={el} />
        </Box>
      </div>
    ));

  return (
    <Box sx={{ overflowX: "auto" }}>
      <ConfirmWithdrawal />
      <Box sx={{ minWidth: 1200 }} className="table">
        <div className="table__tr table__tr--head">
          {getTableCell(__("common.date"), tableStyles.head)}
          {getTableCell(__("common.type"), tableStyles.type)}
          {getTableCell(__("common.amount"), tableStyles.amount)}
          {getTableCell(__("common.network"), tableStyles.network)}
          {getTableCell(__("wallet.credited_amount"), tableStyles.credited)}
          {getTableCell(__("wallet.destination"), tableStyles.destination)}
          {getTableCell(__("wallet.status"), tableStyles.status)}
        </div>
        {loading ? (
          renderLoadingSkeleton()
        ) : data.length ? (
          renderTableRows()
        ) : (
          <p>{__("common.not_found")}</p>
        )}
      </Box>
      {totalPages && totalPages !== 1 && (
        <Pagination
          className="pagination"
          count={totalPages}
          page={currentPage}
          onChange={changePage}
          variant="outlined"
          shape="rounded"
        />
      )}
    </Box>
  );
};

export default Crypto;
