import React from "react";

const ErrorIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.0037"
        cy="10.0037"
        r="9.00375"
        stroke="#D33031"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 14L13.7782 6.22182"
        stroke="#D33031"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6 6L13.7782 13.7782"
        stroke="#D33031"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ErrorIcon;
