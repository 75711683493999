const create = {
  en: {
    "bonnus.title": "Bonnus account",
    "bonnus.title_page": "Activate your bonus account",
    "bonnus.label_randomcode": "Enter the bonus account code here",
    "bonnus.button_submit": "Activate",
  },
  es: {
    "bonnus.title": "Cuenta bono",
    "bonnus.title_page": "Activa tu cuenta bono",
    "bonnus.label_randomcode": "Coloca aquí el código de la cuenta bono",
    "bonnus.button_submit": "Activar",
  },
};

export default create;
