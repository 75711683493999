import React, { useCallback, useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Status from "@components/library/UI/Status";
import { Pagination } from "@mui/material";
import { intermediaryInstance } from "@helpers/axios/private.axios";
import { useTranslation } from "@helpers/translate";

const tableStyles = {
  date: { flex: "0 0 21%" },
  type: { flex: "0 0 18%" },
  paymentMethod: { flex: "0 0 23.5%" },
  amount: { flex: "0 0 16%" },
  status: { flex: "0 0 21.5%", textAlign: "right", paddingRight: "40px" },
};

const Fiat = ({ operation }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const { __ } = useTranslation();

  const getFiatHistory = useCallback(async () => {
    try {
      setLoading(true);
      const operationParam =
        operation !== "all" ? `operation=${operation}` : "";
      const resp = await intermediaryInstance.get(
        `/gateway/history/fiat/transactions?${operationParam}&page=${currentPage}&paginate=10`
      );
      const { items, pagination } = resp.data.data;
      setData(items);
      setLoading(false);
      setTotalPages(pagination.totalPages);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [currentPage, operation]);

  useEffect(() => {
    getFiatHistory();
  }, [getFiatHistory]);

  const changePage = (_, page) => setCurrentPage(page);

  const getTableCell = (content, style, className = "") => (
    <Box sx={style} className={`table__td ${className}`}>
      <p className="table__text">{content}</p>
    </Box>
  );

  const renderLoadingSkeleton = () =>
    [1, 2, 3].map((item) => (
      <div key={item} className="table__tr">
        <Skeleton className="table__skeleton" variant="rounded" width="100%" />
      </div>
    ));

  const renderTableRows = () =>
    data.map((el) => (
      <div key={el.id} className="table__tr">
        {getTableCell(el.created_at, tableStyles.date)}
        {getTableCell(
          el.operation === "deposit"
            ? __("wallet.deposit_type")
            : __("wallet.withdraw_type"),
          tableStyles.type,
          "table__text--bg"
        )}
        {getTableCell(
          el.gateway_title,
          tableStyles.paymentMethod,
          "table__text--bg"
        )}
        {getTableCell(`$${el.amount}`, tableStyles.amount)}
        <Box sx={tableStyles.status} className="table__td">
          <Status status={el.status} transaction={el} />
        </Box>
      </div>
    ));

  return (
    <>
      <Box sx={{ overflowX: "auto" }}>
        <Box sx={{ minWidth: 960 }} className="table">
          <div className="table__tr table__tr--head">
            {getTableCell(__("common.date"), tableStyles.date)}
            {getTableCell(__("common.type"), tableStyles.type)}
            {getTableCell(
              __("common.payment_method"),
              tableStyles.paymentMethod
            )}
            {getTableCell(__("common.amount"), tableStyles.amount)}
            {getTableCell(__("common.status"), tableStyles.status)}
          </div>
          {loading ? (
            renderLoadingSkeleton()
          ) : data.length ? (
            renderTableRows()
          ) : (
            <p>{__("common.not_found")}</p>
          )}
        </Box>
      </Box>
      {totalPages && totalPages !== 1 && (
        <Pagination
          className="pagination"
          count={totalPages}
          page={currentPage}
          onChange={changePage}
          variant="outlined"
          shape="rounded"
        />
      )}
    </>
  );
};

export default Fiat;
