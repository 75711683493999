export const history = {
  en: {
    "history.trade_history": "Trade History",
    "history.open_orders": "Open Orders",

    "history.purchase_time": "Purchase time",
    "history.closing_time": "Closing time",
    "history.asset": "Asset",
    "history.investments": "Investments",
    "history.total": "Total P/L",
    "history.equity": "Equity",
    "history.expiration_time": "Expiration time",
    "history.countdown_time": "Countdown time",
    "history.time": "Time",
  },

  es: {
    "history.trade_history": "Historial de operaciones",
    "history.open_orders": "Órdenes abiertas",

    "history.purchase_time": "Hora de compra",
    "history.closing_time": "Hora de cierre",
    "history.asset": "Activo",
    "history.investments": "Inversiones",
    "history.total": "Total de ganancias/pérdidas",
    "history.equity": "Patrimonio",
    "history.expiration_time": "Hora de expiración",
    "history.countdown_time": "Tiempo de cuenta regresiva",
    "history.time": "Tiempo",
  },
};

export default history;
