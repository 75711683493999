import { ALERT, USER } from "../../constants";
import error from "@components/library/UI/Alert/icons/error";
import { intermediaryInstance } from "@helpers/axios/private.axios";

export const post2FA =
  ({ data, secret, user_id }) =>
  async (dispatch) => {
    try {
      const { code } = data;

      const resp = await intermediaryInstance.post(
        "/auth/validate-google-auth-token",
        {
          token: code,
          secret,
          user_id,
        }
      );

      const {
        data: { verified, message },
      } = resp.data;

      dispatch({ type: USER.USER_FIELD, payload: { verified } });
      dispatch({
        type: verified ? ALERT.ALERT_SUCCESS : ALERT.ALERT_ERROR,
        payload: message,
      });

      return resp.data;
    } catch (e) {
      const { message } = e.response.data;
      dispatch({ type: ALERT.ALERT_ERROR, payload: message });
      throw error;
    }
  };

export const post2FASms =
  ({ code, user_id }) =>
  async (dispatch) => {
    try {
      const resp = await intermediaryInstance.post(
        "/user/change/phone/verify",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            user_id,
            code,
          },
        }
      );

      const {
        kindMessage,
        data: {
          item: { app_2fa_type },
        },
      } = resp.data;

      dispatch({ type: USER.USER_FIELD, payload: { app_2fa_type } });
      dispatch({ type: ALERT.ALERT_SUCCESS, payload: kindMessage });

      return resp.data;
    } catch (e) {
      const { kindMessage } = e.response.data;
      dispatch({ type: ALERT.ALERT_ERROR, payload: kindMessage });
      throw error;
    }
  };

export const post2FASmsInit =
  ({ phone, user_id }) =>
  async (dispatch) => {
    try {
      const resp = await intermediaryInstance.post(
        "/user/change/phone/init",
        {
          phone,
        },
        {
          headers: {
            "Content-Type": "application/json",
            user_id,
          },
        }
      );

      dispatch({ type: ALERT.ALERT_SUCCESS, payload: resp.data.kindMessage });

      return true;
    } catch (e) {
      const { kindMessage } = e.response.data;
      dispatch({ type: ALERT.ALERT_ERROR, payload: kindMessage });
      throw error;
    }
  };
