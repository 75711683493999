import React from "react";
export const LeftArrowIcon = (props) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5466 12.4465V8.29316V4.55317C10.5466 3.91317 9.7733 3.59317 9.31996 4.0465L5.86663 7.49983C5.3133 8.05317 5.3133 8.95317 5.86663 9.5065L7.17996 10.8198L9.31996 12.9598C9.7733 13.4065 10.5466 13.0865 10.5466 12.4465Z"
        fill="#707070"
      />
    </svg>
  );
};

export default LeftArrowIcon;
