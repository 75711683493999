import axios from "@helpers/axios/public.axios";
import { ALERT, AUTH } from "../../constants";

export const recoverPassword =
  ({ email, isUserInApp }) =>
  async (dispatch) => {
    try {
      const response = await axios.post("/auth/forgot-password", {
        email,
        isUserInApp,
      });

      const { kindMessage } = response.data;

      if (!isUserInApp) {
        dispatch({ type: ALERT.ALERT_SUCCESS, payload: kindMessage });
      }

      return response.data.data;
    } catch (e) {
      dispatch({ type: AUTH.AUTH_ERROR, payload: e.response.data.message });
      throw e;
    }
  };
