export const TRY_DEMO = "/try-demo";
export const TRADING_HISTORY = "/trading-history";
export const MAIN_PAGE = "/";
export const AUTH_PAGE = "/auth/login";
export const AUTH_SIGN_UP = "/auth/signup";
export const PROFILE_PAGE = "/profile";
export const UPDATED_PASSWORD = "/auth/updated-password";

export const NO_FOOTER_PAGES = [TRY_DEMO, MAIN_PAGE];
export const TRADING_PAGES = [TRY_DEMO, MAIN_PAGE];
export const WALLET_PAGE = "/wallet";
export const WALLET_DEPOSIT = "/wallet/deposit";
export const WALLET_WITHDRAWAL = "/wallet/withdrawal";
export const SETTINGS_PAGE = "/settings";
export const TRADING_HISTORY_NEW = "/trading-history";

export const NAVIGATE_TO_EMAIL = [
  WALLET_PAGE,
  WALLET_DEPOSIT,
  WALLET_WITHDRAWAL,
  TRADING_HISTORY_NEW,
  SETTINGS_PAGE,
  PROFILE_PAGE,
];
