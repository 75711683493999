import axios from "axios";
import config from "../../config";
import { GLOBALS } from "@constants";
import { saveState } from "@helpers/storage/storeLocalData";
import store from "src/redux/store";
import { ALERT, AUTH } from "src/constants";

const privateInstance = axios.create({
  baseURL: config.api_url,
});

export const intermediaryInstance = axios.create({
  baseURL: config.api_url_intermediary,
  headers: {
    api_key: "ev6ZMe3vJihgdxzgcwZ8VoEEPmALCZG9L",
  },
});

export const customersInstance = axios.create({
  baseURL: config.api_url_customers,
  headers: {
    api_key: "ev6ZMe3vJihgdxzgcwZ8VoEEPmALCZG9L",
  },
});

export const configurateToken = (tokenLegacy) => {
  privateInstance.defaults.headers.common.Authorization = `Bearer ${tokenLegacy}`;
  privateInstance.defaults.headers.token = tokenLegacy;
};

export const configurateIntermediaryToken = (tokenLegacy, tokenJWT, user) => {
  intermediaryInstance.defaults.headers.common.Authorization = `Bearer ${tokenLegacy}`;
  intermediaryInstance.defaults.headers["user_token"] = tokenLegacy;
  intermediaryInstance.defaults.headers["access_token"] = tokenJWT;
  intermediaryInstance.defaults.headers["user_id"] = user.id;
};

export const configurateHash = (hash) => {
  privateInstance.defaults.headers.hash = hash;
  intermediaryInstance.defaults.headers.hash = hash;
};

export const removeUserSession = () => {
  const boState = JSON.parse(
    window.localStorage.getItem(GLOBALS.local_state_name) || "{}"
  );
  saveState(GLOBALS.local_state_name, { ...boState, auth: {} });
  store.dispatch({ type: AUTH.AUTH_LOGOUT });
  window.socket.close();
};

const handleErrorResponse = (error) => {
  if (error.response === undefined || error.response.status === 401) {
    removeUserSession();
  }
  return Promise.reject(error);
};

privateInstance.interceptors.response.use(
  (response) => response,
  handleErrorResponse
);

intermediaryInstance.interceptors.response.use(function (response) {
  if (response.headers.access_token) {
    store.dispatch({
      type: AUTH.AUTH_UPDATE_TOKEN,
      payload: response.headers.access_token,
    });
  }

  if (
    response.data?.name === "Unauthorized" &&
    response.data?.httpCode === 401
  ) {
    store.dispatch({
      type: ALERT.ALERT_ERROR,
      payload: "La sesión ha expirado. Por favor, iniciar sesión nuevamente.",
    });
    setTimeout(() => {
      removeUserSession();
    }, 2000);
  }

  return response;
}, handleErrorResponse);

customersInstance.interceptors.response.use(
  (response) => response,
  handleErrorResponse
);

export default privateInstance;
