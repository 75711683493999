import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const DollarIcon = () => {
  const styles = useStyles();

  return (
    <>
      <svg
        width="71"
        height="71"
        viewBox="0 0 71 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69788 35.5003C3.69788 17.9365 17.9361 3.69824 35.5 3.69824C53.0638 3.69824 67.302 17.9365 67.302 35.5003C67.302 53.0641 53.0638 67.3024 35.5 67.3024C17.9361 67.3024 3.69788 53.0641 3.69788 35.5003ZM35.5 8.13574C20.3869 8.13574 8.13538 20.3873 8.13538 35.5003C8.13538 50.6134 20.3869 62.8649 35.5 62.8649C50.613 62.8649 62.8645 50.6134 62.8645 35.5003C62.8645 20.3873 50.613 8.13574 35.5 8.13574Z"
          fill={styles.main}
          stroke="#181818"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0555 17.75C33.8921 17.75 32.949 18.6931 32.949 19.8566V20.6063H32.2C27.4871 20.6063 23.6666 24.4268 23.6666 29.1397C23.6666 33.8481 27.4799 37.6658 32.1867 37.673L32.2004 37.6731H32.949V46.3136H27.2019C26.0386 46.3136 25.0952 47.2567 25.0952 48.4202C25.0952 49.584 26.0391 50.5268 27.2021 50.5268H32.949V51.2766C32.949 52.44 33.8921 53.3831 35.0555 53.3831C36.2189 53.3831 37.1621 52.44 37.1621 51.2766V50.5268H37.9132C42.626 50.5268 46.4465 46.7063 46.4465 41.9934C46.4465 37.2805 42.626 33.46 37.9132 33.46H37.1621V24.8194H42.9113C44.0746 24.8194 45.0179 23.8764 45.0179 22.7129C45.0179 21.549 44.074 20.6063 42.911 20.6063H37.1621V19.8566C37.1621 18.6931 36.2189 17.75 35.0555 17.75ZM27.8797 29.1397C27.8797 26.7536 29.814 24.8194 32.2 24.8194H32.949V33.46H32.2108L32.2 33.46C29.814 33.46 27.8797 31.5257 27.8797 29.1397ZM37.1621 46.3136V37.6731H37.9132C40.2992 37.6731 42.2334 39.6073 42.2334 41.9934C42.2334 44.3794 40.2992 46.3136 37.9132 46.3136H37.1621Z"
          fill={styles.main}
          stroke="#181818"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
