import { useEffect } from "react";
import "./index.sass";
import CloseCircle from "@assets/images/icons/close-circle.svg";
import { useDispatch } from "react-redux";
import { setErrorBoundary } from "@actions/trading";
const ErrorFallback = ({ resetErrorBoundary, __ }) => {
  const dispatch = useDispatch();
  const retryCount = parseInt(localStorage.getItem("retryCount"), 10) || 0;
  useEffect(() => {
    dispatch(setErrorBoundary(true));
    return () => {
      dispatch(setErrorBoundary(false));
    };
  }, [dispatch]);

  return (
    <div
      role='alert'
      className='TVChartContainer tradingview-widget error-boundary'>
      <div className='error-boundary__content'>
        <div className='error-boundary__content__title'>
          <img
            src={CloseCircle}
            style={{ width: "20px", height: "20px" }}
            alt='Error'
          />
          <h3>{__("boundary.firstMessage")}</h3>
        </div>
        <p>{__("boundary.paragraph")}</p>
        <button className='error-boundary__button' onClick={resetErrorBoundary}>
          {retryCount >= 3
            ? __("boundary.secondtButton")
            : __("boundary.firstButton")}
        </button>
      </div>
    </div>
  );
};

export default ErrorFallback;
