import React, { useState, useEffect } from "react";
import TradingAside from "@components/pages/Trading/components/TradingAside";
import TradingHistory from "@components/pages/Trading/components/TradingHistory";
import MyTradingViewWidget from "@components/pages/Trading/components/TradingViewWidget/TradingViewWidget";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "@helpers/translate";
import Popup from "@components/library/UI/Popup";
import Gift from "@components/pages/Trading/components/Gift";
import "./Trading.sass";
import { Helmet } from "react-helmet";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./common/errorBoundary";
import useErrorBoundary from "./common/useErrorBoundary";

export const Trading = () => {
  const [giftActive, setGiftActive] = useState(false);
  const { id } = useSelector((state) => state.user.user);
  const { __ } = useTranslation();
  const { retryCount, handleRetryCount, setError } = useErrorBoundary();

  useEffect(() => {
    const isRegistered = Cookies.get("registered");

    if (isRegistered && isRegistered === id) {
      setGiftActive(true);
      Cookies.remove("registered");
    }
  }, []);

  return (
    <div className='trading'>
      <Helmet>
        <title>
          {__("seo.main_title")} {__("seo.divider")} {__("seo.title")}
        </title>
      </Helmet>
      <ErrorBoundary
        FallbackComponent={(props) => <ErrorFallback {...props} __={__} />}
        onReset={handleRetryCount}
        onError={() => setError(true)}
        resetKeys={[retryCount]}>
        <MyTradingViewWidget />
        <TradingHistory />
      </ErrorBoundary>

      <TradingAside />
      <Popup active={giftActive} setActive={setGiftActive}>
        <Gift setPopup={setGiftActive} />
      </Popup>
    </div>
  );
};

export default Trading;
