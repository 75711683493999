import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../config";

const useStyles = () => {
  const { theme } = useSelector((state) => state.theme);
  const [styles, setStyles] = useState(getCurrentColors(theme));
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    setStyles(
      isAuth ? getCurrentColors(theme) : getCurrentColors(config.default_theme)
    );
  }, [theme, isAuth]);

  return styles;
};

const getCurrentColors = (theme = "bw-default") => {
  let palette = {};

  switch (theme) {
    case "bw-default":
      palette = {
        main: "#FFFFFF",
        lineArrowDown: "#FFFFFF",
        lineArrowDownBg: "#FFFFFF",
        lineArrowUp: "#FFFFFF",
        lineArrowUpBg: "#FFFFFF",
        arrowUpHistory: "#FFFFFF",
        arrowUpHistoryBg: "#249C65",
        arrDownHistory: "#FFFFFF",
        arrDownHistoryBg: "#CE4F36",
        secColor: "#ffffff",

        widget: {
          bgWidget: "#202020",
          grid: "rgba(255, 242, 204, 0.06)",
        },
      };
      break;

    default:
      palette = {
        main: "#FFFFFF",
        lineArrowDown: "#FFFFFF",
        lineArrowDownBg: "#FFFFFF",
        lineArrowUp: "#FFFFFF",
        lineArrowUpBg: "#FFFFFF",
        arrowUpHistory: "#FFFFFF",
        arrowUpHistoryBg: "#249C65",
        arrDownHistory: "#FFFFFF",
        arrDownHistoryBg: "#CE4F36",
        secColor: "#ffffff",

        widget: {
          bgWidget: "#202020",
          grid: "rgba(255, 242, 204, 0.06)",
        },
      };
  }

  return palette;
};

export default useStyles;
