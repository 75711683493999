import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import { CheckSuccessIcon, FailedIcon } from "@components/library";
import { useLocation } from "react-router-dom";
import { useTranslation } from "@helpers/translate";
import axios from "@helpers/axios/private.axios";
import "./index.sass";

const OndatoPopup = () => {
  const { __ } = useTranslation();
  const [data, setData] = useState({});
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const checkOndatoVerification = async () => {
    const queryParams = new URLSearchParams(location.search);
    const paramStatus = queryParams.get("status");
    if (paramStatus) {
      try {
        const resp = await axios.get(`/ondato/check`);
        const { status, message } = resp.data;

        setData({
          status: status === "success" ? 2 : 1,
          message,
        });

        setShowPopup(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    checkOndatoVerification();
  }, []);

  return (
    <Transition in={showPopup} timeout={500} mountOnEnter unmountOnExit>
      <div
        className={`popup ${showPopup ? "active" : ""}`}
        onClick={() => setShowPopup(false)}
      >
        <div onClick={(e) => e.stopPropagation()} className="popup__content">
          <div className="ondatoPopup">
            <div className="ondatoPopup__pic">
              {data.status === 2 ? <CheckSuccessIcon /> : <FailedIcon />}
            </div>
            <h2 className="ondatoPopup__title">
              {data.status === 2
                ? __("common.successful")
                : __("common.failed")}
            </h2>
            <p className="ondatoPopup__text">{data.message}</p>
          </div>
          <button
            onClick={() => setShowPopup(false)}
            type="button"
            className="popup__close"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </Transition>
  );
};
export default OndatoPopup;
