import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const ArrowUpHistory = () => {
  const styles = useStyles();

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M23.8333 8.46116V17.5287C23.8333 21.472 21.4825 23.8228 17.5391 23.8228H8.46079C4.51746 23.8337 2.16663 21.4828 2.16663 17.5395V8.46116C2.16663 4.51783 4.51746 2.16699 8.46079 2.16699H17.5283C21.4825 2.16699 23.8333 4.51783 23.8333 8.46116Z"
          fill={styles.arrowUpHistoryBg}
        />
        <path
          d="M13.5272 6.71185L17.7842 10.843C18.0719 11.1222 18.0719 11.5844 17.7842 11.8637C17.4964 12.143 17.0201 12.143 16.7323 11.8637L13.7455 8.96519V18.7778C13.7455 19.1726 13.4081 19.5 13.0012 19.5C12.5944 19.5 12.257 19.1726 12.257 18.7778V8.96519L9.27016 11.8637C8.98239 12.143 8.50608 12.143 8.21831 11.8637C8.06946 11.7193 8 11.5363 8 11.3533C8 11.1704 8.07938 10.9778 8.21831 10.843L12.4753 6.71185C12.6142 6.57704 12.8028 6.5 13.0012 6.5C13.1997 6.5 13.3882 6.57704 13.5272 6.71185Z"
          fill={styles.arrowUpHistory}
        />
      </svg>
    </>
  );
};
