import { useEffect } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { useDispatch, useSelector } from "react-redux";
import { CHANEL, GLOBALS, TRADING } from "../../constants";
import { ALERT, BIDS } from "../../constants";

import config from "../../config";
import { getClosedHistory } from "@actions/bids";
import { updateWallets } from "@actions";

export const ConnectionToSockets = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user.user);
  const { pair } = useSelector((state) => state.trading);
  const socketUrl = config.socketUrl;
  let interval = null;

  const resubscribe = () => {
    try {
      if (window.socket.readyState === WebSocket.OPEN) {
        window.socket.send(
          JSON.stringify({
            action: "subscribe",
            pair: pair.slug,
            user_id: id,
          })
        );
        dispatch({
          type: TRADING.CHANGE_PAIR,
          payload: pair,
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleMessages = (event) => {
    clearInterval(interval);

    const { action, channel, message, data, bid, symbol, ...rest } = JSON.parse(
      event.data
    );

    if (action === "price_update") {
      dispatch({
        type: TRADING.PAIR_PRICE_UPDATE,
        payload: { ...rest, slug: symbol, name: symbol, bid },
      });
    }

    if (action === "bid_error") {
      const type = message.includes("20%")
        ? ALERT.ALERT_WARNING
        : ALERT.ALERT_ERROR;

      dispatch({
        type: type,
        payload: message,
      });
    }

    if (channel === id) {
      if (action === "bid_opened") {
        dispatch({
          type: BIDS.SHOW_NOTIFICATION,
          payload: data.bid,
        });
        dispatch({
          type: BIDS.ADD_TO_OPENED_HISTORY,
          payload: data.bid,
        });
        dispatch(updateWallets());
      }

      if (action === "bid_error") {
        dispatch({
          type: ALERT.ALERT_ERROR,
          payload: message,
        });
      }

      if (action === "bid_closed") {
        dispatch({
          type: BIDS.SHOW_NOTIFICATION,
          payload: bid,
        });
        dispatch({ type: BIDS.REMOVE_FROM_OPENED_HISTORY, payload: bid.id });
        dispatch(getClosedHistory({ count: 1 }));
        dispatch(updateWallets());
      }
    }
  };

  const handleClose = () => {
    const token = JSON.parse(localStorage.getItem(GLOBALS.local_state_name))
      .auth.token;
    if (!token) return;
    interval = setInterval(() => {
      if (window.socket.readyState === WebSocket.OPEN) {
        clearInterval(interval);
        return;
      }
      startPriceSocketConnection();
    }, 5000);
  };

  const startPriceSocketConnection = () => {
    window.socket = new WebSocket(socketUrl);
    window.socket.onopen = () => resubscribe();
    window.socket.onclose = () => handleClose();
    window.socket.onmessage = (event) => handleMessages(event);
  };

  useEffect(() => {
    startPriceSocketConnection();
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: config.pusher_key,
      cluster: config.pusher_cluster,
      forceTLS: true,
    });

    dispatch({
      type: CHANEL.CHANGE_USER_CHANEL,
      payload: window.Echo.channel(`User.${id}`),
    });

    const handleOnline = () => {
      dispatch({
        type: ALERT.ALERT_SUCCESS,
        payload: "Reconnected",
      });
    };

    const handleOffline = () => {
      dispatch({
        type: ALERT.ALERT_ERROR,
        payload: "Reconnecting...",
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return null;
};
