import { createStore, compose, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import reducers from "../reducers";
import { saveState, loadState } from "@helpers";
import { GLOBALS } from "@constants";
import {
  configurateIntermediaryToken,
  configurateToken,
} from "@helpers/axios/private.axios";
import { configurateHash } from "../helpers/axios/private.axios";

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const middlewares = applyMiddleware(thunk);

const prevState = loadState(GLOBALS.local_state_name);

const getState = (oldState) => {
  if (!oldState) {
    return createStore(reducers, composeEnhancers(middlewares));
  }
  return createStore(reducers, oldState, composeEnhancers(middlewares));
};

const store = getState(prevState);

store.subscribe(() => {
  const data = store.getState();
  if (data.auth.token !== null && data.auth.tokenLegacy !== null) {
    configurateIntermediaryToken(data.auth.tokenLegacy, data.auth.token, data.auth.user);
  }

  if (data.auth.tokenLegacy !== null) {
    configurateToken(data.auth.tokenLegacy);
  }

  if (data.auth.hash !== null) {
    configurateHash(data.auth.hash);
  }

  saveState(GLOBALS.local_state_name, {
    auth: {
      token: data.auth.token,
      tokenLegacy: data.auth.tokenLegacy,
      user: data.auth.user,
      isAuth: data.auth.isAuth,
      isDemoUser: data.auth.isDemoUser,
      hash: data.auth.hash,
    },
    localization: {
      locale: data.localization.locale,
    },
    theme: {
      theme: data.theme.theme,
    },
    user: {
      active_wallet: data.user,
      loading: true,
    },
    trading: {
      pair: data.trading.pair,
      userPairList: data.trading.userPairList,
      pairsPrice: {},
      errorBoundary: data.trading.errorBoundary,
    },
  });
});

export default store;
