import { Header } from "../Header/";
import { Footer } from "../Footer/";

import "./layout.sass";

export const Layout = ({ children }) => {
  return (
    <div className={`base-wrap`}>
      <div className="base-wrap__header">
        <Header />
      </div>

      <div className="base-wrap__content">{children}</div>

      <div className="base-wrap__footer">{<Footer />}</div>
    </div>
  );
};
