export const transactionTransform = (transaction) => {
  return {
    transaction,
    currency: "USDT",
    amount: transaction.amount,
    method: "NOWPAYMENTS",
    fee: transaction.fee,
    withdrawn: "2",
  };
};
