import { ALERT } from "../../constants";
import { intermediaryInstance } from "@helpers/axios/private.axios";

export const changeUserPassword =
  ({ code, newPassword, oldPassword }) =>
  async (dispatch) => {
    const resp = await intermediaryInstance.post("/auth/change-password", {
      code,
      newPassword,
      oldPassword,
    });
    dispatch({
      type: ALERT.ALERT_SUCCESS,
      payload: "Contraseña actualizada con éxito",
    });
    return resp.data;
  };
