import { Grid } from "@mui/material";
import { useTranslation } from "@helpers/translate";

export const CollapseClose = ({
  openTime,
  closeTime,
  opening_price,
  closing_price,
  type,
  status,
  profit,
  contribution
}) => {
  const { __ } = useTranslation();

  return (
    <>
      <Grid container columnSpacing="1rem" rowSpacing="20px">
        <Grid item xs={6}>
          <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
            {__("trading.open_time")}:
          </h5>
          <time className="historyDayItem__text">
            <strong>{openTime}</strong>
          </time>
        </Grid>
        <Grid item xs={6}>
          <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
            {__("trading.closing_at")}:
          </h5>
          <time className="historyDayItem__text">
            <strong>{closeTime}</strong>
          </time>
        </Grid>
        <Grid item xs={6}>
          <ul className="historyDayItem__list">
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.open_price")}:
              </h5>
            </li>
            <li className="historyDayItem__item">
              <time className="historyDayItem__text">
                <strong>{opening_price}</strong>
              </time>
            </li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <ul className="historyDayItem__list">
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.close_price")}:
              </h5>
            </li>
            <li className="historyDayItem__item">
              <time className="historyDayItem__text">
                <strong>{closing_price}</strong>
              </time>
            </li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <ul className="historyDayItem__list">
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.position")}:
              </h5>
              <p
                className={`historyDayItem__text ${
                  type === "up"
                    ? "historyDayItem__text--red"
                    : "historyDayItem__text--green"
                }`}
              >
                <strong>
                  {type === "up"
                    ? __("trading.position.buy")
                    : __("trading.position.sell")}
                </strong>
              </p>
            </li>
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                {__("trading.result")}:
              </h5>
              <p
                className={`historyDayItem__text ${
                  status === 2
                    ? "historyDayItem__text--green"
                    : status === 0
                    ? "historyDayItem__text--red"
                    : "historyDayItem__text--white"
                }`}
              >
                <strong>
                  {status == 2
                    ? __("trading.result.won")
                    : status == 0
                    ? __("trading.result.lost")
                    : __("trading.result.tie")}
                </strong>
              </p>
            </li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <ul className="historyDayItem__list">
            <li className="historyDayItem__item">
              <h5 className="historyDayItem__title" style={{ color: "#FFFF" }}>
                P&L:
              </h5>
              <p
                className={`historyDayItem__text ${
                  status === 2
                    ? "historyDayItem__text--green"
                    : status === 0
                    ? "historyDayItem__text--red"
                    : "historyDayItem__text--white"
                }`}
              >
                <strong>
                  
                  {status === 2
                    ? "+$" + profit
                    : status === 0 && "-$" + contribution}
                </strong>
              </p>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};
