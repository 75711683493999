import React, { useState, useEffect, useRef } from "react";
import { CloseIcon } from "@components/library";
import { useDispatch, useSelector } from "react-redux";
import { TRADING } from "../../../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Box } from "@mui/material";

export const ListOfPairs = () => {
  const { userPairList, pair: activePair } = useSelector(
    (state) => state.trading
  );
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const [prevPairListLength, setPrevPairListLength] = useState(
    userPairList.length
  );

  const removePair = (slug) => {
    dispatch({ type: TRADING.DELETE_FROM_USER_PAIR_LIST, payload: slug });
  };

  const changeCurrentPair = (pair) => {
    dispatch({ type: TRADING.CHANGE_PAIR, payload: pair });
  };

  useEffect(() => {
    setPrevPairListLength(userPairList.length);
  }, [userPairList.length]);

  return (
    <div className="pairsList">
      <Swiper
        ref={swiperRef}
        onSlidesUpdated={(swiper) => {
          if (swiper.slides.length > prevPairListLength) {
            swiper.slideTo(swiper.slides.length - 1);
          }
        }}
        resistanceRatio={0}
        spaceBetween={8}
        slidesPerView="auto"
      >
        {userPairList.map((pair, i) => (
          <SwiperSlide key={i + "_" + pair}>
            <Box
              onClick={() => changeCurrentPair(pair)}
              className={`pairTab${
                activePair.slug === pair.slug ? " active" : ""
              }`}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removePair(pair.slug);
                }}
                type="button"
                className="pairTab__close"
              >
                <CloseIcon />
              </button>

              <div className="pairTab__content">
                <picture className="pairTab__icon">
                  <img src={pair.cover} />
                </picture>
                <div className="pairTab__info">
                  <p className="pairTab__name">{pair.name}</p>
                </div>
              </div>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
