import React, { useEffect, useState } from "react";
import { Button, MenuItem, Select } from "@mui/material";
import { RefreshIcon, EyeIcon, EyeClosedIcon } from "@components/library";
import { AnimatedCounter } from "react-animated-counter";
import { getWallets, changeWallet, updateWalletEvent } from "@actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "@helpers/translate";
import { useNavigate, useLocation } from "react-router-dom";

const Mode = () => {
  const dispatch = useDispatch();
  const { active_wallet, wallets } = useSelector((state) => state.wallet);
  const { __ } = useTranslation();
  const [showBalance, setShowBalance] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const changeWalletAction = (e) => {
    const wallet = wallets.find((item) => item.type === e.target.value);
    if (
      wallet.type === "bono" &&
      (location.pathname === "/wallet" ||
        location.pathname == "/wallet/deposit")
    ) {
      navigate("/wallet/withdrawal");
    }
    dispatch(changeWallet(wallet));
  };

  const handleWalletClick = () => {
    setShowBalance(!showBalance);
  };

  useEffect(() => {
    dispatch(getWallets());
    dispatch(updateWalletEvent());
  }, []);

  return (
    <div>
      <Button
        className="header__mini-btn"
        onClick={handleWalletClick}
        styles={{ cursor: "pointer" }}
      >
        {showBalance ? <EyeClosedIcon /> : <EyeIcon />}
      </Button>
      <Select
        MenuProps={{
          className: "mode-select-menu",
        }}
        variant="standard"
        className="mode-select"
        value={active_wallet.type ? active_wallet.type : "demo"}
        onChange={changeWalletAction}
        autoWidth
        IconComponent={(props) => {
          return (
            <svg
              {...props}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9467 5.45312H7.79341H4.05341C3.41341 5.45312 3.09341 6.22646 3.54674 6.67979L7.00008 10.1331C7.55341 10.6865 8.45341 10.6865 9.00674 10.1331L10.3201 8.81979L12.4601 6.67979C12.9067 6.22646 12.5867 5.45312 11.9467 5.45312Z"
                fill="#707070"
              />
            </svg>
          );
        }}
      >
        {wallets.map((wallet) => {
          return (
            <MenuItem key={wallet.id} value={wallet.type}>
              <div className="mode-item">
                <RefreshIcon className="mode-item__icon" />
                <div className="mode-item__content">
                  <p className="mode-item__name">
                    {wallet.type === "demo"
                      ? __("common.demo")
                      : wallet.type === "real"
                      ? __("common.real")
                      : __("common.bonnus")}
                  </p>
                  <h5 className="mode-item__value">
                    {showBalance ? (
                      <AnimatedCounter
                        value={wallet.balance}
                        fontSize="11px"
                        color="#ffffff"
                      />
                    ) : (
                      "*******"
                    )}{" "}
                    USD
                  </h5>
                </div>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default Mode;
