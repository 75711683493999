import React from "react";

export const SettingsIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7501 7.68385C15.2417 7.68385 14.6251 6.61718 15.3751 5.30885C15.8084 4.55052 15.5501 3.58385 14.7917 3.15052L13.3501 2.32552C12.6917 1.93385 11.8417 2.16718 11.4501 2.82552L11.3584 2.98385C10.6084 4.29218 9.37508 4.29218 8.61675 2.98385L8.52508 2.82552C8.15008 2.16718 7.30008 1.93385 6.64175 2.32552L5.20008 3.15052C4.44175 3.58385 4.18341 4.55885 4.61675 5.31718C5.37508 6.61718 4.75841 7.68385 3.25008 7.68385C2.38341 7.68385 1.66675 8.39218 1.66675 9.26718V10.7339C1.66675 11.6005 2.37508 12.3172 3.25008 12.3172C4.75841 12.3172 5.37508 13.3838 4.61675 14.6922C4.18341 15.4505 4.44175 16.4172 5.20008 16.8505L6.64175 17.6755C7.30008 18.0672 8.15008 17.8339 8.54175 17.1755L8.63341 17.0172C9.38341 15.7089 10.6167 15.7089 11.3751 17.0172L11.4667 17.1755C11.8584 17.8339 12.7084 18.0672 13.3667 17.6755L14.8084 16.8505C15.5667 16.4172 15.8251 15.4422 15.3917 14.6922C14.6334 13.3838 15.2501 12.3172 16.7584 12.3172C17.6251 12.3172 18.3417 11.6089 18.3417 10.7339V9.26718C18.3334 8.40052 17.6251 7.68385 16.7501 7.68385ZM10.0001 12.7088C8.50842 12.7088 7.29175 11.4922 7.29175 10.0005C7.29175 8.50885 8.50842 7.29218 10.0001 7.29218C11.4917 7.29218 12.7084 8.50885 12.7084 10.0005C12.7084 11.4922 11.4917 12.7088 10.0001 12.7088Z"
        fill="#707070"
      />
    </svg>
  );
};
