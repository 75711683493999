import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const LineArrowDown = () => {
  const styles = useStyles();

  return (
    <>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M2.16675 17.5395V8.46116C2.16675 4.51783 4.51758 2.16699 8.46092 2.16699H17.5284C21.4826 2.16699 23.8334 4.51783 23.8334 8.46116V17.5287C23.8334 21.472 21.4826 23.8228 17.5392 23.8228H8.46092C4.51758 23.8337 2.16675 21.4828 2.16675 17.5395Z"
          fill={styles.lineArrowDownBg}
        />
        <path
          d="M12.4259 20.0742L7.77835 15.4267C7.46418 15.1125 7.46418 14.5925 7.77835 14.2783C8.09252 13.9642 8.61252 13.9642 8.92668 14.2783L12.1875 17.5392V6.5C12.1875 6.05583 12.5559 5.6875 13 5.6875C13.4442 5.6875 13.8125 6.05583 13.8125 6.5V17.5392L17.0734 14.2783C17.3875 13.9642 17.9075 13.9642 18.2217 14.2783C18.3842 14.4408 18.46 14.6467 18.46 14.8525C18.46 15.0583 18.3842 15.2642 18.2217 15.4267L13.5742 20.0742C13.4225 20.2258 13.2167 20.3125 13 20.3125C12.7833 20.3125 12.5775 20.2258 12.4259 20.0742Z"
          fill={styles.lineArrowDown}
        />
      </svg>
    </>
  );
};
