import React from "react";

export const TradingIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4917 1.66602H6.50841C3.47508 1.66602 1.66675 3.47435 1.66675 6.50768V13.4827C1.66675 16.5244 3.47508 18.3327 6.50841 18.3327H13.4834C16.5167 18.3327 18.3251 16.5244 18.3251 13.491V6.50768C18.3334 3.47435 16.5251 1.66602 13.4917 1.66602ZM6.35841 15.1243C6.35841 15.466 6.07508 15.7493 5.73341 15.7493C5.39175 15.7493 5.10841 15.466 5.10841 15.1243V13.3993C5.10841 13.0577 5.39175 12.7743 5.73341 12.7743C6.07508 12.7743 6.35841 13.0577 6.35841 13.3993V15.1243ZM10.6251 15.1243C10.6251 15.466 10.3417 15.7493 10.0001 15.7493C9.65841 15.7493 9.37508 15.466 9.37508 15.1243V11.666C9.37508 11.3243 9.65841 11.041 10.0001 11.041C10.3417 11.041 10.6251 11.3243 10.6251 11.666V15.1243ZM14.8917 15.1243C14.8917 15.466 14.6084 15.7493 14.2667 15.7493C13.9251 15.7493 13.6417 15.466 13.6417 15.1243V9.94102C13.6417 9.59935 13.9251 9.31602 14.2667 9.31602C14.6084 9.31602 14.8917 9.59935 14.8917 9.94102V15.1243ZM14.8917 7.30768C14.8917 7.64935 14.6084 7.93268 14.2667 7.93268C13.9251 7.93268 13.6417 7.64935 13.6417 7.30768V6.49935C11.5167 8.68268 8.85841 10.2243 5.88341 10.966C5.83341 10.9827 5.78341 10.9827 5.73341 10.9827C5.45008 10.9827 5.20008 10.791 5.12508 10.5077C5.04175 10.1743 5.24175 9.83268 5.58341 9.74935C8.39175 9.04935 10.8917 7.57435 12.8751 5.49102H11.8334C11.4917 5.49102 11.2084 5.20768 11.2084 4.86602C11.2084 4.52435 11.4917 4.24102 11.8334 4.24102H14.2751C14.3084 4.24102 14.3334 4.25768 14.3667 4.25768C14.4084 4.26602 14.4501 4.26602 14.4917 4.28268C14.5334 4.29935 14.5667 4.32435 14.6084 4.34935C14.6334 4.36602 14.6584 4.37435 14.6834 4.39102C14.6917 4.39935 14.6917 4.40768 14.7001 4.40768C14.7334 4.44102 14.7584 4.47435 14.7834 4.50768C14.8084 4.54102 14.8334 4.56602 14.8417 4.59935C14.8584 4.63268 14.8584 4.66602 14.8667 4.70768C14.8751 4.74935 14.8917 4.79102 14.8917 4.84102C14.8917 4.84935 14.9001 4.85768 14.9001 4.86602V7.30768H14.8917Z"
        fill="#707070"
      />
    </svg>
  );
};
