import { ALERT, WALLET } from "../../constants";
import { intermediaryInstance } from "@helpers/axios/private.axios";

export const fiatWithdrawal =
  ({ amount, bank_account, id }) =>
  async (dispatch) => {
    try {
      const resp = await intermediaryInstance.post(`/gateway/${id}/request`, {
        amount,
        bank_account,
      });
      dispatch({
        type: WALLET.SHOW_WITHDRAWAL,
        payload: resp.data.data,
      });

      return resp;
    } catch (error) {
      console.error(error);
      dispatch({
        type: ALERT.ALERT_ERROR,
        payload: error.response.data.message,
      });
    }
  };

export const cryptoWithdrawal =
  ({ amount, bank_account, wallet_id }) =>
  async (dispatch) => {
    try {
      const resp = await intermediaryInstance.post(
        `/transaction/request-withdraw`,
        {
          wallet_id,
          amount,
          wallet_address: bank_account,
          currency: "usdttrc20",
        }
      );

      dispatch({
        type: WALLET.SHOW_WITHDRAWAL,
        payload: resp.data.data,
      });

      return resp;
    } catch (error) {
      console.error(error);
      dispatch({
        type: ALERT.ALERT_ERROR,
        payload: error.response.data.kindMessage,
      });
    }
  };

export const confirmWithdrawal =
  ({ code, id }) =>
  async (dispatch) => {
    const resp = await intermediaryInstance.post(
      `/gateway/transaction/${id}/verify`,
      {
        code,
      }
    );

    dispatch({
      type: WALLET.HIDE_WITHDRAWAL,
    });
    dispatch({
      type: ALERT.ALERT_SUCCESS,
      payload: resp.data.message,
    });

    return resp;
  };
