import React from "react";

export const ProfileIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 1.66602C7.81675 1.66602 6.04175 3.44102 6.04175 5.62435C6.04175 7.76602 7.71675 9.49935 9.90008 9.57435C9.96675 9.56602 10.0334 9.56602 10.0834 9.57435C10.1001 9.57435 10.1084 9.57435 10.1251 9.57435C10.1334 9.57435 10.1334 9.57435 10.1417 9.57435C12.2751 9.49935 13.9501 7.76602 13.9584 5.62435C13.9584 3.44102 12.1834 1.66602 10.0001 1.66602Z"
        fill="#707070"
      />
      <path
        d="M14.2333 11.7914C11.9083 10.2414 8.11663 10.2414 5.77497 11.7914C4.71663 12.4997 4.1333 13.4581 4.1333 14.4831C4.1333 15.5081 4.71663 16.4581 5.76663 17.1581C6.9333 17.9414 8.46663 18.3331 9.99997 18.3331C11.5333 18.3331 13.0666 17.9414 14.2333 17.1581C15.2833 16.4497 15.8666 15.4997 15.8666 14.4664C15.8583 13.4414 15.2833 12.4914 14.2333 11.7914Z"
        fill="#707070"
      />
    </svg>
  );
};
