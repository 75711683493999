import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const TimeIcon = ({ className = "" }) => {
  const styles = useStyles();

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1321_31258)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.9375 9C0.9375 4.5472 4.5472 0.9375 9 0.9375C13.4528 0.9375 17.0625 4.5472 17.0625 9C17.0625 13.4528 13.4528 17.0625 9 17.0625C4.5472 17.0625 0.9375 13.4528 0.9375 9ZM9 2.0625C5.16852 2.0625 2.0625 5.16852 2.0625 9C2.0625 12.8315 5.16852 15.9375 9 15.9375C12.8315 15.9375 15.9375 12.8315 15.9375 9C15.9375 5.16852 12.8315 2.0625 9 2.0625Z"
          fill={styles.secColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00295 3.9375C9.31361 3.93753 9.56542 4.1894 9.56539 4.50006L9.56496 8.77033L12.58 11.7853C12.7996 12.005 12.7996 12.3612 12.58 12.5808C12.3603 12.8005 12.0041 12.8005 11.7845 12.5808L8.60469 9.40105C8.49919 9.29554 8.43993 9.15245 8.43994 9.00324L8.44039 4.49994C8.44042 4.18928 8.69229 3.93747 9.00295 3.9375Z"
          fill={styles.secColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1321_31258">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimeIcon;
