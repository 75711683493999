export const profile = {
  en: {
    "profile.my_profile": "My Profile",
    "profile.full_name": "Full name",
    "profile.first_name": "First name",
    "profile.last_name": "Last name",
    "profile.email": "Email",
    "profile.birth": "Date of birth",
    "profile.v_status": "Verification status",
    "profile.start_verification": "Start verification",
    "profile.phone_number": "Phone number",
    "profile.country": "Country",
  },

  es: {
    "profile.my_profile": "Mi perfil",
    "profile.full_name": "Nombre completo",
    "profile.first_name": "Nombres",
    "profile.last_name": "Apellidos",
    "profile.email": "Correo electrónico",
    "profile.birth": "Fecha de nacimiento",
    "profile.v_status": "Estado de verificación",
    "profile.start_verification": "Comenzar verificación",
    "profile.phone_number": "Número de teléfono",
    "profile.country": "País",
  },
};

export default profile;
