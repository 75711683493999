import { useState, useEffect } from "react";
import { removeUserSession } from "@helpers/axios/private.axios";

const useErrorBoundary = () => {
  const count = parseInt(localStorage.getItem("retryCount"), 10) || 0;
  const [retryCount, setRetryCount] = useState(count);
  const [error, setError] = useState(false);

  const clearCacheAndCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
    });
    localStorage.clear();
    sessionStorage.clear();
    removeUserSession();
  };

  useEffect(() => {
    localStorage.setItem("retryCount", retryCount);
    retryCount > 3 && clearCacheAndCookies();
  }, [retryCount]);

  useEffect(() => {
    if(!error) {
      resetRetryCount();
      return;
    }
    window.addEventListener("beforeunload", handleRetryCount);

    return () => {
      window.removeEventListener("beforeunload", handleRetryCount);
    };
  }, [error]);

  const handleRetryCount = () => {
    const count = parseInt(localStorage.getItem("retryCount"), 10) || 0;
    setRetryCount(count + 1);
  };

  const resetRetryCount = () => {
    setRetryCount(0);
    localStorage.removeItem("retryCount");
  };

  return { retryCount, handleRetryCount, setError, resetRetryCount };
};

export default useErrorBoundary;
