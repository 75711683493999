import React from "react";
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BIDS } from "../../../constants";
import "./index.sass";
import { BidClosedNotification } from "./BidClosedNotification";
import { BidOpenedNotification } from "./BidOpenedNotification";

export const BidNotification = () => {
  const { notifications } = useSelector((state) => state.bids);
  const dispatch = useDispatch();

  const handleClose = (event, reason, id) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: BIDS.HIDE_NOTIFICATION, payload: id });
  };

  return (
    <div className="bid-notification-wrap">
      {notifications
        ?.map((notification) => {
          const { id, result } = notification;

          return (
            <Snackbar
              key={id}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              autoHideDuration={4000}
              onClose={(event, reason) => handleClose(event, reason, id)}
              className="snackbar"
              onClick={() => handleClose("", "", id)}
            >
              <div className="bid-notification">
                {!!result || result === 0 ? (
                  <BidClosedNotification {...notification} result={result} />
                ) : (
                  <BidOpenedNotification {...notification} />
                )}
              </div>
            </Snackbar>
          );
        })}
    </div>
  );
};
