import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const ArrowDownHistory = () => {
  const styles = useStyles();

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M2.16671 17.5393L2.16671 8.47183C2.16671 4.5285 4.51754 2.17766 8.46087 2.17766L17.5392 2.17766C21.4825 2.16683 23.8334 4.51766 23.8334 8.46099L23.8334 17.5393C23.8334 21.4827 21.4825 23.8335 17.5392 23.8335L8.47171 23.8335C4.51754 23.8335 2.16671 21.4827 2.16671 17.5393Z"
          fill={styles.arrDownHistoryBg}
        />
        <path
          d="M12.4728 19.2881L8.21583 15.157C7.92806 14.8778 7.92806 14.4156 8.21583 14.1363C8.5036 13.857 8.97991 13.857 9.26767 14.1363L12.2545 17.0348L12.2545 7.22222C12.2545 6.82741 12.5919 6.5 12.9988 6.5C13.4056 6.5 13.743 6.82741 13.743 7.22222L13.743 17.0348L16.7298 14.1363C17.0176 13.857 17.4939 13.857 17.7817 14.1363C17.9305 14.2807 18 14.4637 18 14.6467C18 14.8296 17.9206 15.0222 17.7817 15.157L13.5247 19.2881C13.3858 19.423 13.1972 19.5 12.9988 19.5C12.8003 19.5 12.6118 19.423 12.4728 19.2881Z"
          fill={styles.arrDownHistory}
        />
      </svg>
    </>
  );
};
