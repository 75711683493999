import { TRADING } from "src/constants";
import store from "../../../../../redux/store";
import { intermediaryInstance } from "@helpers/axios/private.axios";

const services = {
  getHistory: async ({ symbol, to, resolution, from }) => {
    const result = await intermediaryInstance
      .get(
        `/trading-pairs-history?symbol=${symbol}&from=${
          from * 1000
        }&to=${to}&resolution=${resolution}`
      )
      .then((res) => res.data?.data)
      .catch((e) => {
        console.log(e);

        return [];
      });

    return result.reverse();
  },
};

const configurationData = {
  supported_resolutions: ["1", "2", "4", "5", "15", "30", "45"],
  convertionRates: [60, 300, 900, 1800, 3600],
  max_days_before: {
    1: 1000 * 60 * 60 * 24 * 2,
    5: 1000 * 60 * 60 * 24 * 2,
  },
};

let currentSub;

const history = {};

const getAndUpdateLastCandle = (symbol, resolution, lastTick) => {
  if (!history[symbol] || !history[symbol][resolution]?.length) return null;
  const candles = history[symbol][resolution];
  const lastCandle = candles[candles.length - 1];
  if (lastCandle.time / 1000 >= lastTick.time / 1000) {
    const candle = {
      ...lastCandle,
      close: lastTick.bid,
      high: Math.max(lastCandle.high, lastTick.bid),
      low: Math.min(lastCandle.low, lastTick.bid),
    };
    return updateLastCandle(symbol, resolution, candle);
  }
  const candle = {
    time: lastTick.time,
    open: lastCandle.close,
    close: lastTick.bid,
    high: lastTick.bid,
    low: lastTick.bid,
  };
  return addNewCandle(symbol, resolution, candle);
};

const updateLastCandle = (symbol, resolution, candle) => {
  if (!history[symbol] || !history[symbol][resolution]?.length) return null;
  const candles = history[symbol][resolution];
  candles[candles.length - 1] = candle;
  history[symbol][resolution] = candles;
  return candle;
};

const addNewCandle = (symbol, resolution, candle) => {
  if (!history[symbol] || !history[symbol][resolution]?.length) return null;
  const candles = history[symbol][resolution];
  candles.push(candle);
  history[symbol][resolution] = candles;
  return candle;
};

const state = store.getState();

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },
  getBars: (
    symbolInfo,
    resolution,
    preiodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    setTimeout(async () => {
      const { from, to, firstDataRequest } = preiodParams;
      const endRequestTime = firstDataRequest
        ? Date.now()
        : Math.min(Date.now(), to * 1000);

      if (
        !firstDataRequest ||
        !history[symbolInfo.ticker] ||
        !history[symbolInfo.ticker][resolution]?.length
      ) {
        const data = await services.getHistory({
          to: endRequestTime,
          symbol: symbolInfo.ticker,
          from,
          resolution,
        });

        if (data.length === 0) {
          onErrorCallback("No data here");

          store.dispatch({
            type: TRADING.DELETE_FROM_USER_PAIR_LIST,
            payload: symbolInfo.ticker,
          });
          return;
        }

        if (firstDataRequest) {
          history[symbolInfo.ticker] = {
            ...history[symbolInfo.ticker],
            [resolution]: data.reverse(),
          };
        } else {
          const firstCandle = history[symbolInfo.ticker][resolution][0];
          if (!firstCandle) return;
          const firstCandleTime = firstCandle.time;
          const previousElements = data.reverse().filter((el) => {
            const elTIme = el.time;
            return elTIme < firstCandleTime;
          });
          history[symbolInfo.ticker][resolution] = [
            ...previousElements,
            ...history[symbolInfo.ticker][resolution],
          ];
        }
      }

      const bars = history[symbolInfo.ticker][resolution];

      if (bars.length === 0) {
        onErrorCallback("Error en getbars");
        return;
      }

      onHistoryCallback(bars, { noData: true });
    }, 0);
  },
  resolveSymbol: (symbolName, onSymbolResolvedCallback) =>
    setTimeout(async () => {
      const symbol = {
        ticker: symbolName,
        session: "24x7",
        timezone: "America/Lima",
        minmov: 1,
        pricescale: 100000,
        has_intraday: true,
        has_weekly_and_monthly: false,
        volume_precision: 1,
        data_status: "streaming",
        visible_plots_set: "ohlcv",
      };

      onSymbolResolvedCallback(symbol);
    }, 0),
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) => {
    const userId = state.auth.user?.id;
    if (!userId) return;
    currentSub = {
      pair: symbolInfo.ticker,
      user_id: userId,
      channel: subscriberUID,
    };

    window.socket.send(
      JSON.stringify({
        action: "subscribe",
        ...currentSub,
      })
    );

    store.subscribe(() => {
      const state = store.getState();
      const e = state.trading.pairsPrice[symbolInfo.ticker];

      if (!e) return;

      const candleTime = Number(resolution) * 60;
      const time = Math.round(Number(e.ts) / candleTime) * candleTime;
      const candle = getAndUpdateLastCandle(e.slug, resolution, { ...e, time });
      if (candle) onRealtimeCallback(candle);
      if (onResetCacheNeededCallback) {
        onResetCacheNeededCallback();
      }
    });
  },
  unsubscribeBars: (subscriberUID) => {
    console.log("[unsubscribe bars]", { subscriberUID });
  },
};
