import React from "react";

export const HistoryIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5751 5.81628C16.8668 5.03294 15.6834 4.64128 13.9668 4.64128H13.7668V4.60794C13.7668 3.20794 13.7668 1.47461 10.6334 1.47461H9.36678C6.23345 1.47461 6.23345 3.21628 6.23345 4.60794V4.64961H6.03345C4.30845 4.64961 3.13345 5.04128 2.42511 5.82461C1.60011 6.74128 1.62511 7.97461 1.70845 8.81628L1.71678 8.87461L1.77757 9.51289C1.79183 9.66267 1.87258 9.79805 1.99858 9.88027C2.20063 10.0121 2.51037 10.2109 2.70011 10.3163C2.81678 10.3913 2.94178 10.4579 3.06678 10.5246C4.49178 11.3079 6.05845 11.8329 7.65011 12.0913C7.72511 12.8746 8.06678 13.7913 9.89178 13.7913C11.7168 13.7913 12.0751 12.8829 12.1334 12.0746C13.8334 11.7996 15.4751 11.2079 16.9584 10.3413C17.0084 10.3163 17.0418 10.2913 17.0834 10.2663C17.4036 10.0853 17.7355 9.86361 18.0401 9.64493C18.1533 9.56366 18.2255 9.43753 18.2409 9.29902L18.2501 9.21628L18.2918 8.82461C18.3001 8.77461 18.3001 8.73294 18.3084 8.67461C18.3751 7.83294 18.3584 6.68294 17.5751 5.81628ZM10.9084 11.5246C10.9084 12.4079 10.9084 12.5413 9.88344 12.5413C8.85845 12.5413 8.85845 12.3829 8.85845 11.5329V10.4829H10.9084V11.5246ZM7.42511 4.64128V4.60794C7.42511 3.19128 7.42511 2.66628 9.36678 2.66628H10.6334C12.5751 2.66628 12.5751 3.19961 12.5751 4.60794V4.64961H7.42511V4.64128Z"
        fill="#707070"
      />
      <path
        d="M17.2597 11.509C17.6137 11.3417 18.0209 11.6223 17.9855 12.0123L17.7 15.1583C17.525 16.8249 16.8416 18.5249 13.175 18.5249H6.82495C3.15828 18.5249 2.47495 16.8249 2.29995 15.1666L2.02924 12.1888C1.9942 11.8033 2.39252 11.5233 2.74555 11.6819C3.70476 12.113 5.31676 12.8064 6.35274 13.0854C6.51675 13.1295 6.6499 13.2476 6.7243 13.4003C7.23851 14.4555 8.32769 15.0166 9.89162 15.0166C11.4401 15.0166 12.5426 14.4339 13.0588 13.3755C13.1333 13.2227 13.2664 13.1047 13.4304 13.0603C14.5322 12.7618 16.2536 11.9845 17.2597 11.509Z"
        fill="#707070"
      />
    </svg>
  );
};
