import axios from "axios";
import config from "src/config";

const instance = axios.create({
  baseURL: config.api_url_intermediary,
  headers: {
    api_key: config.api_url_deposito_api_key,
  },
});

export default instance;
