import store from "src/redux/store";

export const PostBid =
  ({ type, pair_name, expatriation, contribution }, userId) =>
  async () => {
    try {
      const state = store.getState();
      const payload = JSON.stringify({
        action: "bid_open",
        user_id: userId,
        type,
        pair: pair_name,
        expatriation,
        contribution,
        user_token: state.auth.token,
      });
      window.socket.send(payload);
    } catch (e) {
      console.error(e);
    }
  };
