import React from "react";

const SuccessIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.0037"
        cy="12.5037"
        r="9.00375"
        stroke="#00AD64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.44238 12.3387L10.6103 14.5066L10.5963 14.4926L15.4873 9.60156"
        stroke="#00AD64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessIcon;
