import axios from "@helpers/axios/public.axios";
import { AUTH } from "../../constants";

export const registration =
  ({ email, password, name, lastname, partnerCode }) =>
  async (dispatch) => {
    try {
      let body = {
        email,
        password,
        firstName: name,
        lastName: lastname,
      };

      if (partnerCode) {
        body = { ...body, sponsorCode: partnerCode };
      }

      await axios.post("/auth/register", {
        ...body,
      });
    } catch (e) {
      dispatch({ type: AUTH.AUTH_ERROR, payload: e.response.data.message });
      throw e;
    }
  };
