export const wallet = {
  en: {
    "wallet.available_balance": "Available balance",
    "wallet.back": "Back to Wallet",
    "wallet.trade": "Trade",
    "wallet.withdrawal": "Withdrawal",
    "wallet.deposit": "Deposit",
    "wallet.transaction_history": "Transaction History",
    "wallet.credited_amount": "Credited amount",
    "wallet.destination": "Destination",
    "wallet.hash": "TX Hash",
    "wallet.enter_amount": "Enter the amount in USDT (TRC20)",
    "wallet.number": "Wallet number",
    "wallet.min_deposit": "Minimum deposit amount",
    "wallet.min_withdraw": "Minimum withdraw amount",
    "wallet.max_amount": "Max amount per transaction",
    "wallet.withdrawal_funds": "Withdrawal funds",
    "wallet.fee": "Fee",
    "wallet.received": "Will be received",
    "wallet.select_currency": "Select currency",
    "wallet.status": "Status",

    "wallet.cryptocurrencies": "Cryptocurrencies",

    "wallet.confirm_withdrawal": "Confirm withdrawal",
    "wallet.confirm_withdrawal_text": "Confirm this action to withdrawal",
    "wallet.currency": "Currency",
    "wallet.platform_fee": "Platform Fee",
    "wallet.will_be_withdrawn": "Will be withdrawn",
    "wallet.enter_2fa": "Enter the 2FA 6-digit Code",
    "wallet.digit": "6-digit Code",
    "wallet.warningDemo":
      "You are using a Demo account, it is not possible to withdraw/deposit money, do you want to change to a Real account?",
    "wallet.warningUnverified":
      "You will not be able to withdraw money until you complete the verification process.",
    "wallet.send2FA": "Send 2FA code",
    "wallet.deposit_popup1": "The deposit in the amount of",
    "wallet.deposit_popup2": "was successful",
    "wallet.deposit_popup3": "was failed",
    "wallet.deposit_type": "Deposit",
    "wallet.withdraw_type": "Withdraw",
    "wallet.no_limits": "no limits",
  },

  es: {
    "wallet.available_balance": "Saldo disponible",
    "wallet.back": "Volver a la cartera",
    "wallet.trade": "Operar",
    "wallet.withdrawal": "Retiro",
    "wallet.deposit": "Depósito",
    "wallet.transaction_history": "Historial de transacciones",
    "wallet.credited_amount": "Cantidad acreditada",
    "wallet.destination": "Destino",
    "wallet.hash": "Hash TX",
    "wallet.enter_amount": "Ingresa la cantidad en USDT (TRC20)",
    "wallet.number": "Número de billetera",
    "wallet.min_deposit": "Cantidad mínima de depósito",
    "wallet.min_withdraw": "Cantidad mínima de retiro",
    "wallet.max_amount": "Cantidad máxima por transacción",
    "wallet.withdrawal_funds": "Retirar fondos",
    "wallet.fee": "Tarifa",
    "wallet.received": "Se recibirá",
    "wallet.select_currency": "Seleccionar moneda",
    "wallet.status": "Estado",

    "wallet.cryptocurrencies": "Criptomonedas",

    "wallet.confirm_withdrawal": "Confirmar retiro",
    "wallet.confirm_withdrawal_text": "Confirma esta acción para retirar",
    "wallet.currency": "Moneda",
    "wallet.platform_fee": "Tarifa de plataforma",
    "wallet.will_be_withdrawn": "Será retirado",
    "wallet.enter_2fa": "Ingresa el código de 2FA de 6 dígitos",
    "wallet.digit": "Código de 6 dígitos",
    "wallet.warningDemo":
      "Estás usando una cuenta de Demo, no es posible retirar/depositar dinero, ¿quieres cambiar a una cuenta Real?",
    "wallet.warningUnverified":
      "No podrás retirar dinero hasta que completes el proceso de verificación.",
    "wallet.send2FA": "Enviar código 2FA",
    "wallet.deposit_popup1": "El depósito por un monto de",
    "wallet.deposit_popup2": "fue exitoso",
    "wallet.deposit_popup3": "falló",
    "wallet.deposit_type": "Depósito",
    "wallet.withdraw_type": "Retiro",
    "wallet.no_limits": "sin límites",
  },
};

export default wallet;
