import React from "react";
import useStyles from "@helpers/theme/useStyles";

export const LineArrowUp = () => {
  const styles = useStyles();

  return (
    <>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M23.8334 8.46067V17.5282C23.8334 21.4715 21.4825 23.8223 17.5392 23.8223H8.46085C4.51752 23.8332 2.16669 21.4823 2.16669 17.539V8.46067C2.16669 4.51734 4.51752 2.1665 8.46085 2.1665H17.5284C21.4825 2.1665 23.8334 4.51734 23.8334 8.46067Z"
          fill={styles.lineArrowUpBg}
        />
        <path
          d="M13.5741 5.92583L18.2216 10.5733C18.5358 10.8875 18.5358 11.4075 18.2216 11.7217C17.9075 12.0358 17.3875 12.0358 17.0733 11.7217L13.8125 8.46083V19.5C13.8125 19.9442 13.4441 20.3125 13 20.3125C12.5558 20.3125 12.1875 19.9442 12.1875 19.5V8.46083L8.92664 11.7217C8.61248 12.0358 8.09248 12.0358 7.77831 11.7217C7.61581 11.5592 7.53998 11.3533 7.53998 11.1475C7.53998 10.9417 7.62664 10.725 7.77831 10.5733L12.4258 5.92583C12.5775 5.77417 12.7833 5.6875 13 5.6875C13.2166 5.6875 13.4225 5.77417 13.5741 5.92583Z"
          fill={styles.lineArrowUp}
        />
      </svg>
    </>
  );
};
