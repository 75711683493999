import axios from "axios";
import { ALERT, WALLET } from "../../constants";
import config from "src/config";

export const gatewayDeposit =
  ({ amount, user_id }) =>
  async (dispatch) => {
    try {
      const resp = await axios.post(
        `${config.api_url_deposit}/transaction/deposit`,
        {
          amount,
          user_id: user_id.replaceAll('"', ""),
        },
        {
          headers: {
            "Content-Type": "application/json",
            api_key: config.api_url_deposito_api_key,
          },
        }
      );

      const {
        data: { data },
      } = resp;

      const {
        item: { type, url_form },
      } = data;

      if (type === "form") {
        const div = document.createElement("div");
        div.hidden = true;

        div.innerHTML = resp.data.data.form;

        document.body.appendChild(div);
        const form = div.querySelector("form");
        form.submit();
        div.remove();
      }

      if (type === "link") {
        window.location = url_form;
      }

      return resp;
    } catch (error) {
      dispatch({
        type: ALERT.ALERT_ERROR,
        payload: error.response.data.message,
      });
    }
  };

export const getTransaction = (transaction_id) => async (dispatch) => {
  try {
    const response = await axios.get(`/transaction/${transaction_id}`);
    const { status, amount, currency } = response.data.data;

    const data = {
      status,
      amount,
      currency,
    };

    dispatch({
      type: WALLET.SHOW_POPUP,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALERT.ALERT_ERROR,
      payload: error.response.data.message,
    });
  }
};
