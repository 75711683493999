import axios, { customersInstance } from "@helpers/axios/private.axios";
import { ALERT, USER } from "../../constants";

export const updateUser =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await axios.post("/users/update", {
        ...data,
      });

      dispatch({ type: USER.USER_FIELD, payload: resp.data.data.user });

      return resp.data;
    } catch (e) {
      const { message } = e.response.data;
      dispatch({ type: ALERT.ALERT_ERROR, payload: message });
      throw e;
    }
  };

export const updateUserKyc =
  ({ data }, userId) =>
  async (dispatch) => {
    try {
      const resp = await customersInstance.patch(
        `/customers/update/profile/${userId}`,
        {
          ...data,
        }
      );

      dispatch({
        type: ALERT.ALERT_SUCCESS,
        payload: "Completed",
      });

      const { data: infoKyc } = resp.data;

      window.location.href = infoKyc.verification_url;

      return resp.data;
    } catch (e) {
      const { message } = e.response.data;
      dispatch({ type: ALERT.ALERT_ERROR, payload: message });
      throw e;
    }
  };
