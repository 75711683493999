import { Box, Typography } from "@mui/material";
import { useTranslation } from "@helpers/translate";
import { BID_RESULTS } from "src/constants";

export const BidClosedNotification = ({
  pair_name,
  type,
  opening_price,
  profit,
  payout,
  contribution,
  expatriation,
  closing_price,
  result
}) => {
  const { __ } = useTranslation();
  const duration = `${Number(expatriation.split(":")[1])} min`;
  const numberFormat = (value) =>
    new Intl.NumberFormat("es-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        minWidth: "270px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "baseline",
          gap: "10px"
        }}
      >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <h2>{result === BID_RESULTS.WIN ? "✅" : "❌"}</h2>
          <h2>{pair_name}</h2>
          <h2>{type === "up" ? "🔼" : "🔽"}</h2>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            fontSize: "17px",
            alignItems: "baseline",
            color: result === BID_RESULTS.WIN ? "#00FF00" : "#FF0000"
          }}
        >
          <Typography variant="span" sx={{ fontSize: "22px" }}>
            {result === BID_RESULTS.WIN
              ? "+$" + numberFormat(profit)
              : "-$" + numberFormat(contribution)}
          </Typography>
          <Typography variant="span" sx={{ fontSize: "15px" }}>
            ({result === BID_RESULTS.WIN ? "+" + payout : "100"}%)
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.open_price")}
            </Typography>
            <Typography variant="span" sx={{ fontSize: "16px" }}>
              {Number(opening_price)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.close_price")}
            </Typography>
            <Typography variant="span" sx={{ fontSize: "16px" }}>
              {closing_price}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.duration")}
            </Typography>
            <Typography variant="span" sx={{ fontSize: "16px" }}>
              {duration}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.amount")}
            </Typography>
            <Typography variant="span" sx={{ fontSize: "16px" }}>
              ${contribution}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px"
            }}
          >
            <Typography variant="span" sx={{ color: "gray", fontSize: "12px" }}>
              {__("trading.result")}
            </Typography>
            <Typography
              variant="span"
              sx={{
                fontSize: "16px",
                color: result === BID_RESULTS.WIN ? "#00FF00" : "#FF0000"
              }}
            >
              {result === BID_RESULTS.WIN
                ? __("trading.result.won")
                : result === BID_RESULTS.LOST 
                  ? __("trading.result.lost")
                  : __("trading.result.tie")
                }
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
