import LogoBw from "@assets/images/logo-bw-grey.png";
import { Link } from "react-router-dom";
import moment from "moment";
import config from "../../../config";
import { Box, Typography } from "@mui/material";

import "./footer.sass";
import { useTranslation } from "@helpers/translate";

export const Footer = () => {
  const { __ } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__top">
        <Link to={"/"} className="footer__logo">
          <img src={LogoBw} alt="logo" />
        </Link>

        <div className="footer__text-wrap">
          <p className="footer__text">
            {__("seo.title")} © {moment().year()}. {__("main.footer.t1")}
          </p>
          <p className="footer__text">
            {__("main.footer.t2")}:{" "}
            <a href={`mailto:${config.infoEmail}`}>{config.infoEmail}</a>
          </p>
          <p className="footer__text">
            {__("main.footer.t3")}:{" "}
            <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
          </p>
        </div>
      </div>

      <div className="footer__bottom">
        <nav className="footer__nav">
          <ul className="footer__list">
            <li className="footer__item">
              <Link to="/page/privacy-policy" className="footer__link">
                {__("main.privacy_policy")}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/page/terms-and-conditions" className="footer__link">
                {__("main.terms_of_use")}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/page/aml-policy" className="footer__link">
                {__("main.aml_policy")}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/page/cookie-policy" className="footer__link">
                {__("main.cookie_policy")}
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <Box
        className="footer__text-wrap"
        sx={{ width: "100%", padding: "10px 30px" }}
      >
        <p className="footer__text">
          TradingView es una plataforma global de gráficos para traders e
          inversores que cuenta con más de 70 millones de usuarios en todo el
          mundo. Como nuestro socio de gráficos en World Binary, TradingView
          ofrece soluciones de gráficos avanzados para el análisis técnico en
          profundidad, mientras que en su propia plataforma, TradingView ofrece
          características para la investigación de mercado y la educación. Esto
          permite a los traders realizar un seguimiento de los precios de las
          criptomonedas, estudiar las finanzas de las empresas,{" "}
          <Typography component="a"
            href={"https://es.tradingview.com/economic-calendar/"}
            style={{ color: "white", textDecoration: "underline", fontSize: "12px" }}
            target="_blank"
            rel="dofollow"
          >
            seguir los eventos económicos
          </Typography>{" "}
          o incluso explorar el mercado de ETF, todo ello con datos de mercado
          procedentes de fuentes fiables.
        </p>
      </Box>
    </footer>
  );
};
